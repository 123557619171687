import React from "react";
import { write, utils } from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const JsonToXlsx = ({ jsonData, title }) => {
  const handleDownload = () => {
    const keys = Object.keys(jsonData);
    const rows = [];
    for (let i = 0; i < jsonData[keys[0]].length; i++) {
      const row = {};
      keys.forEach((key) => {
        row[key] = jsonData[key][i];
      });
      rows.push(row);
    }
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const xlsxData = write(workbook, { type: "array", bookType: "xlsx" });
    const blob = new Blob([xlsxData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${title}.xlsx`;
    link.click();
  };

  return (
    <div>
      <button onClick={handleDownload} type="button" className="btn btn-success">
        Download Excel <FontAwesomeIcon icon={faFileExcel} />
      </button>
    </div>
  );
};

export default JsonToXlsx;
