/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "../../utils/Axios";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSection, setCurrentSection] = useState("dataTable");
  const videoUrl = `${axios.defaults.baseURL}/videos`;
  const sliderData = [
    {
      id: 1,
      backgroundVideo: `${videoUrl}/1.mp4`,
      title: "Medan Satu Data",
      caption: "Aplikasi yang kami buat adalah platform pengumpulan data dari seluruh dinas di Kota Medan yang bertujuan untuk memberikan akses mudah dan transparansi dalam mengakses informasi publik tentang pelayanan publik dan infrastruktur kota.",
      section: "dataTable",
    },
    {
      id: 2,
      backgroundVideo: `${videoUrl}/2.mp4`,
      title: "Melancong Di Medan",
      caption:
        "Nikmati pesona bangunan-bangunan tua yang masih bertahan di kota Medan. Dari arsitektur kolonial Belanda hingga seni deco, bangunan-bangunan ini memberikan gambaran tentang sejarah dan keanekaragaman budaya kota ini.",
      section: null,
    },
    {
      id: 3,
      backgroundVideo: `${videoUrl}/3.mp4`,
      title: "Jajan Di Medan",
      caption:
        "Selain bangunan tua yang indah, kota Medan juga menyajikan berbagai kuliner yang patut untuk dicoba. Ayo, jalan-jalan dan rasakan pengalaman kuliner yang tak terlupakan!",
      section: null,
    },
    {
      id: 4,
      backgroundVideo: `${videoUrl}/4.mp4`,
      title: "Pantau Kota Medan",
      caption:
        "Nikmati akses real-time untuk melihat kondisi lalu lintas di kota Medan dan temukan rute alternatif dengan mudah menggunakan menu pemantauan CCTV kami.",
      section: "pantauMedan",
    },
  ];

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    setCurrentSection(sliderData[index].section || "");
  };

  const handleSection = (event) => {
    event.preventDefault();
    const targetElement = document.getElementById(currentSection);
    targetElement.scrollIntoView({ behavior: "smooth" });
    window.scrollTo(0, targetElement.offsetTop - 100);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % sliderData.length);
      setCurrentSection(sliderData[(currentSlide + 1) % sliderData.length].section || "");
    }, 20000);
    return () => clearInterval(intervalId);
  }, [currentSlide, sliderData.length]);

  return (
    <div style={styles.sliderContainer}>
      {sliderData.map((slide, index) => (
        <div key={slide.id} style={styles.slideContainer}>
          <video
            autoPlay
            loop
            muted
            style={{
              ...styles.slide,
              opacity: index === currentSlide ? 1 : 0,
              transform: index === currentSlide ? "scale(1.1)" : "scale(1)",
            }}
          >
            <source src={slide.backgroundVideo} type="video/mp4" />
          </video>
          <div
            style={{
              ...styles.overlay,
              opacity: index === currentSlide ? 0.6 : 0,
              transition: "all 2s ease-in-out",
            }}
          />
          <div
            style={{
              ...styles.caption,
              opacity: index === currentSlide ? 1 : 0,
              transition: "all 2s ease-in-out",
            }}
          >
            <h1 className="text-orange">{slide.title}</h1>
            <p>{slide.caption}</p>
            {slide.section ? (
              <button className="btn btn-primary btn-lg" onClick={handleSection}>
                Selengkapnya
              </button>
            ) : null}
          </div>
        </div>
      ))}
      <div style={styles.navigation}>
        {sliderData.map((slide, index) => (
          <div
            key={slide.id}
            style={{
              ...styles.dot,
              backgroundColor: index === currentSlide ? "white" : "rgba(255, 255, 255, 0.5)",
            }}
            onClick={() => {
              handleSlideChange(index);
              setCurrentSection(sliderData[index].section || "");
            }}
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  sliderContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: "black",
  },
  slideContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  slide: {
    position: "absolute",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    transition: "all 2s ease-in-out",
  },
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "black",
    opacity: 0.8,
    transition: "all 2s ease-in-out",
  },
  caption: {
    position: "absolute",
    top: "75%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "white",
    zIndex: 1,
  },
  navigation: {
    position: "absolute",
    bottom: "5%",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  dot: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    margin: "0 10px",
    cursor: "pointer",
    animation: "fill 12s",
  },
};

export default Slider;