/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTables from "../../tables/DataTable";
import ModalInsert from "../../modal/data/Insert";
import axios from "../../../utils/Axios";
import ChartView from "./ChartView";
import ModalUpdate from "../../modal/data/Update";
import DeleteData from "../../modal/data/Delete";
import { toast } from "react-toastify";

export default function DataDashboard({ total }) {
  const [data, setData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [dataToEdit, setDataToEdit] = useState([]);
  const [dataToDelete, setDataToDelete] = useState([]);
  const [user, setUser] = useState([]);
  const token = localStorage.getItem("token");

  const getData = async () => {
    await axios
      .get("/api/category", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setCatData(result.data.result);
      });

    await axios
      .get("/api/data", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result.data.success) {
          const rs = result.data.result;
          setData(rs);
          total(rs.length);
        } else {
          toast.error(result.data.message);
        }
      });

      await axios
      .get("/api/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data.result);
      });
  };

  const handleEdit = (val) => {
    setDataToEdit(val);
  };

  const handleDelete = (val) => {
    setDataToDelete(val);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="container-fluid mt-2">
        <div className="container p-0 mb-2">
          <h5>Tabel Data</h5>
          <DataTables data={data} editValue={(val) => handleEdit(val)} deleteValue={(val) => handleDelete(val)} />
        </div>

        {/* <div className="container p-0">
          <h5>Chart Data</h5>
          <ChartView data={data.length > 0 ? data : []} />
        </div> */}

        <ModalInsert catData={catData} refreshData={() => getData()} userData={user} />
        <ModalUpdate catData={catData} dataToEdit={dataToEdit} refreshData={() => getData()} />
        <DeleteData data={dataToDelete} refreshData={() => getData()} />
      </div>
    </div>
  );
}
