import React from "react";
import DataTable from "react-data-table-component";

export default function functionalTable({ jsonData }) {
  const columns = Object.keys(jsonData).map((key) => {
    let column = {
      name: key,
      selector: key,
      sortable: true,
    };

    if (typeof jsonData[key][0] === "number") {
      column.cell = (row) =>
        row[key] ? row[key].toLocaleString("id-ID") : "";
    }

    return column;
  });

  const data = jsonData[columns[0].name].map((_, index) => {
    const row = {};
    columns.forEach((col) => {
      row[col.selector] = jsonData[col.selector][index];
    });
    return row;
  });

  return <DataTable columns={columns} data={data} pagination />;
}
