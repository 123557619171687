import React from "react";
import axios from "../../../utils/Axios";
import { toast } from "react-toastify";

export default function DeleteData({ data, refreshData }) {
  const token = localStorage.getItem("token");
  const deleteData = async () => {
    await axios
      .get(`/api/data/delete/${data.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result.data.success) {
          refreshData();
          toast.success(result.data.message);
        } else {
          toast.error(result.data.message);
        }
      });
  };
  return (
    <div className="modal fade" id="deleteData" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Hapus Kategori
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h6>Apakah anda yaking ingin menghapus "{data.title}"?</h6>
          </div>
          <div className="modal-footer">
            <button form="deletetCategoryForm" type="clear" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button
              onClick={() => deleteData()}
              form="deletetCategoryForm"
              type="submit"
              className="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Hapus Data
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
