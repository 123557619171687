import React from "react";
import "./App.css";
import "./styles/Home.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/auth/Register";
import Category from "./components/dashboard/contents/Category";
import Agency from "./components/dashboard/contents/Agency";
import Users from "./components/dashboard/contents/Users";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgot",
      element: <Forgot />,
    },
    {
      path: "/reset-password/:token",
      element: <Reset />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      children: [
        {
          path: "category",
          element: <Category />,
        },
        {
          path: "skpd",
          element: <Agency />,
        },
        {
          path: "users",
          element: <Users />,
        },
      ],
    },
  ]);
  return <div>
    <RouterProvider router={router} />
    <ToastContainer />
  </div>;
}

export default App;
