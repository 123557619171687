/* eslint-disable eqeqeq */
import React, { useState } from "react";
import XlsxToJson from "../../../utils/XlsxToJson";
import InsertTables from "../../tables/InsertTable";
import Line from "../../charts/LineFunction";
import BarChart from "../../charts/BarFunction";
import PieChart from "../../charts/PieFunction";
import axios from "../../../utils/Axios";
import { toast } from "react-toastify";

export default function ModalInsert({ catData, refreshData, userData }) {
  const [jsonData, setJsonData] = useState(null);
  const [title, setTitle] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [dataSubmit, setDataSubmit] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSkpd, setSelectedSkpd] = useState(null);
  const token = localStorage.getItem("token");
  const level = localStorage.getItem("level");

  const resetData = () => {
    setJsonData(null);
    setTitle("");
    setSelectedCat("");
    setDataSubmit([]);
    setSelectedOption("");
    setSelectedSkpd(null);
  };

  const createData = async () => {
    const formData = {
      userId: selectedSkpd,
      chartData: dataSubmit,
      jsonData: jsonData,
      title: title,
      chartType: selectedOption,
      categoryId: selectedCat,
    };

    await axios
      .post("/api/data/create", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result.data.success) {
          toast.success(result.data.message);
          refreshData();
          resetData();
        } else {
          toast.error(result.data.message);
        }
      });
  };

  const changeTitle = (e) => {
    const titleCaseName = e.target.value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    setTitle(titleCaseName);
  };

  return (
    <div className="modal fade" id="insertData" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Tambah Data
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="h-100">
              <div className="row d-flex align-items-center justify-content-center text-white bg-dark rounded pt-3 pb-3 h-25">
                <div className="col-auto">
                  <div className="container">
                    <label htmlFor="insertTitle">Upload File : </label>
                    <XlsxToJson jsonData={(val) => setJsonData(val)} />
                  </div>
                </div>
                <div className="col">
                  <div className="container form-inline">
                    <label htmlFor="insertTitle">Nama Data :</label>
                    <input type="text" className="form-control" id="insertTitle" value={title} onChange={changeTitle} />
                  </div>
                </div>
                <div className="col">
                  <div className="container">
                    <label htmlFor="insertChart">Pilih Kategori :</label>
                    <select
                      className="form-select"
                      id="insertChart"
                      aria-label="Default select example"
                      onChange={(e) => setSelectedCat(e.target.value)}
                    >
                      <option value={null} selected>
                        --- Kategori ---
                      </option>
                      {catData.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {level == 1 ? (
                  <div className="col">
                    <div className="container">
                      <label htmlFor="insertChart">Pilih SKPD :</label>
                      <select
                        className="form-select"
                        id="insertChart"
                        aria-label="Default select example"
                        onChange={(e) => setSelectedSkpd(e.target.value)}
                      >
                        <option value={null} selected>
                          --- SKPD ---
                        </option>
                        {userData.map((val) => (
                          <option key={val.id} value={val.id}>
                            {val.agency.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : null}
                <div className="col">
                  <div className="container">
                    <label htmlFor="insertChart">Pilih tipe chart :</label>
                    <select
                      className="form-select"
                      id="insertChart"
                      aria-label="Default select example"
                      onChange={(e) => setSelectedOption(e.target.value)}
                    >
                      <option value={null} selected>
                        --- Chart ---
                      </option>
                      <option value="line">Line Chart</option>
                      <option value="bar">Bar Chart</option>
                      <option value="pie">Pie Chart</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-3 h-75">
                <div className="col-md-6">
                  <div className="container">
                    <InsertTables tData={jsonData ? jsonData : []} />
                  </div>
                </div>
                <div className="col-md-6">
                  {selectedOption === "line" ? (
                    <Line cData={jsonData ? jsonData : []} submitData={(dt) => setDataSubmit(dt)} />
                  ) : selectedOption === "bar" ? (
                    <BarChart cData={jsonData ? jsonData : []} submitData={(dt) => setDataSubmit(dt)} />
                  ) : selectedOption === "pie" ? (
                    <PieChart cData={jsonData ? jsonData : []} submitData={(dt) => setDataSubmit(dt)} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button onClick={() => createData()} type="button" className="btn btn-primary" data-bs-dismiss="modal">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
