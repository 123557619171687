import axios from "axios";
const token = localStorage.getItem("token");

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const instance = axios.create({
  baseURL: "https://medansatudata-api.metromatika.com",
  // baseURL: "http://103.116.168.181:3000",
  headers: headers,
});

export default instance;
