import React, { useState } from "react";

export default function InsertAgency({ submit }) {
  const [agencyName, setAgencyName] = useState("");
  const [agencyAddress, setAgencyAddress] = useState("");
  const [agencyPhone, setAgencyPhone] = useState("");

  return (
    <div
      className="modal fade"
      id="insertAgency"
      tabIndex={"-1"}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Tambah SKPD
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Nama SKPD"
                onChange={(e) => setAgencyName(e.target.value)}
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Alamat SKPD"
                onChange={(e) => setAgencyAddress(e.target.value)}
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Telepon SKPD"
                onChange={(e) => setAgencyPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button form="insertAgencyForm" type="clear" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button
              onClick={() => submit(agencyName, agencyAddress, agencyPhone)}
              form="insertAgencyForm"
              type="submit"
              className="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
