import React from "react";
import DataTable from "react-data-table-component";
import axios from "../../utils/Axios";
import { toast } from "react-toastify";

export default function UserTable({ data, refreshTable }) {
  const token = localStorage.getItem("token");
  const handleClick = async (active, id) => {
    await axios
      .get(`/api/user/activate-user/${id}/${active}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          refreshTable();
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const switcher = (active, id) => {
    return (
      <div className={`switcher ${active ? "on" : "off"}`} onClick={() => handleClick(!active, id)}>
        <div className="toggle-btn"></div>
      </div>
    );
  };

  const columns = [
    {
      name: <h6 className="m-0 table-header fw-bold">Aktif</h6>,
      selector: (row) => switcher(row.active, row.id),
      width: "70px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Nama</h6>,
      selector: (row) => row.name,
      width: "200px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Alamat</h6>,
      selector: (row) => row.address,
      width: "400px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">NIK</h6>,
      selector: (row) => row.nik,
      center: true,
      width: "200px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Email</h6>,
      selector: (row) => row.email,
      center: true,
      width: "200px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Kontak</h6>,
      selector: (row) => row.contact,
      center: true,
      width: "200px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">SKPD</h6>,
      selector: (row) => row.agency.name,
      center: true,
      width: "250px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Alamat SKPD</h6>,
      selector: (row) => row.agency.address,
      width: "400px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Kontak SKPD</h6>,
      selector: (row) => row.agency.phone,
      center: true,
      width: "200px",
    },
  ];
  return (
    <div>
      <DataTable columns={columns} data={data} pagination dense />
    </div>
  );
}
