import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "../../utils/Axios";
import { toast } from "react-toastify";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const saveToStorage = async (token, level) => {
    localStorage.setItem("token", token);
    localStorage.setItem("level", level);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const data = {
      email: email,
      password: password,
    };

    await axios.post("/api/auth/login", data).then(async (res) => {
      if (res.data.success) {
        await saveToStorage(res.data.token, res.data.level).then(() => {
          navigate("/dashboard");
        });
      } else {
        toast.error(res.data.message);
      }
    });
  };

  const handleGoTo = (uri) => {
    navigate(uri);
  };

  return (
    <div className="login-container d-flex align-items-center justify-content-center">
      <div className="container login-inner-container text-center">
        <img id="login-icon" src={require("../../assets/images/pemko.png")} alt="icon" className="img-responsive" />
        <form className="mb-4 mt-5" id="loginForm" onSubmit={handleLogin}>
          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="email"
                className="form-control ps-2 ipt"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="password"
                className="form-control ps-2 ipt"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
        </form>
        <div className="row">
          <button type="submit" form="loginForm" className="btn btn-primary btn-md w-100 rounded-pill">
            Login
          </button>
          <div className="container text-orange mt-3">
            <span role={"button"} onClick={() => handleGoTo("/")}>
              Home
            </span>
            {" / "}
            <span role={"button"} onClick={() => handleGoTo("/register")}>
              Register
            </span>
            {" / "}
            <span role={"button"} onClick={() => handleGoTo("/forgot")}>
              Forgot Password
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
