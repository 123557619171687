import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Economy() {
  const [newsData, setnewsData] = useState([]);
  const [itemCount, setItemCount] = useState(4);

  const getNews = async () => {
    const res = await axios.get("https://api-berita-indonesia.vercel.app/cnn/ekonomi/");
    setnewsData(res.data.data.posts);
  };

  const dateFormatter = (val) => {
    // Create a new date object from the input date string
    const date = new Date(val);

    // Extract the day, month, and year from the date object
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    // Concatenate the day, month, and year with "-" separator
    const outputDate = `${day}-${month}-${year}`;
    return outputDate;
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <div>
      <div className="row">
        {newsData.slice(0, itemCount).map((val, index) => {
          return (
            <div role={"button"} key={index} className="col-md-3 p-2 articles" onClick={() => window.open(val.link, "_blank")}>
              <div className="container p-0 m-0 bg-white w-100 rounded">
                <img src={val.thumbnail} alt="" className="w-100 rounded-top" />
                <div className="container">
                  <h6 className="fw-bold p-0 m-0 news-text-preview">{val.title}</h6>
                  <span>
                    <FontAwesomeIcon icon={faClock} /> {dateFormatter(val.pubDate)}
                  </span>
                  <span className="news-text-preview">{val.description}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-end">
        <button className="btn btn-primary" onClick={() => setItemCount(itemCount + 4)}>
          Lebih Banyak
        </button>
      </div>
    </div>
  );
}
