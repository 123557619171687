import React, { useEffect, useState } from "react";
import axios from "../../../utils/Axios";
import UserTable from "../../tables/UserTable";

export default function Users({ total }) {
  const [user, setUser] = useState([]);
  const token = localStorage.getItem("token");

  const getData = async () => {
    await axios
      .get("/api/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data.result);
        total(res.data.result.length);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container mt-2">
      <UserTable data={user} refreshTable={getData} />
    </div>
  );
}
