import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Footer() {
  return (
    <div className="container-fluid bg-white pt-3 pb-3">
      <div className="row">
        <div className="col-md-4">
          <div className="container w-100 h-100 d-flex align-items-center justify-content-center">
            <img src={require("../assets/images/diskominfo.png")} alt="" className="h-75" />
          </div>
        </div>
        {/* <div className="col-3">
          <div className="container w-100 h-100 d-flex align-items-center justify-content-center">
            <img src={require("../assets/images/metromatika.png")} alt="" className="h-75" />
          </div>
        </div> */}
        <div className="col-md-4">
          <div className="container w-100 h-100 d-flex align-items-center justify-content-center">
            <span>
              Jl. Kapten Maulana Lubis No.2, Petisah Tengah, Kec. Medan Petisah, Kota Medan, Sumatera Utara 20231
            </span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="container w-100 h-100 d-flex align-items-center justify-content-center">
            <span>
              <FontAwesomeIcon icon={faPhone} /> (061) 4512412
            </span>
          </div>
        </div>
      </div>
      <div className="container text-center mt-3 border-top pt-2">
        <span>© 2023 Dinas Komunikasi dan Informatika Kota Medan</span>
      </div>
    </div>
  );
}
