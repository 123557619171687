import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
export default function BarChart({ title, data, lbl, update, category, agency }) {
  const [tl, setTl] = useState(0);

  useEffect(() => {
    let totalLength = 0;
    for (const item of data) {
      totalLength += item.data.length;
    }
    setTl(totalLength);
  }, [data]);

  return (
    <div className="container w-100 bg-white overflow-auto">
      {title ? <h6 className="pt-2">{title}</h6> : null}
      <div style={{ width: data.length >= 2 && lbl.length > 3 ? (tl * lbl.length) / 1.5 : "auto", height: "50vh" }}>
        <Bar
          data={{
            labels: lbl,
            datasets: data,
          }}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                align: "start",
                display: true,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: data.length >= 4 && lbl.length > 4 ? 5 : 1.5,
          }}
        />
      </div>

      {update ? (
        <span className="small" style={{ fontSize: 12 }}>
          Updated : {update} | Kategori : {category} | Dinas : {agency}
        </span>
      ) : null}
    </div>
  );
}
