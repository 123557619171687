import React, {useState} from "react";
import { read, utils } from "xlsx";

export default function XlsxToJson({jsonData}) {

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array" });
      const sheet_name_list = workbook.SheetNames;
      const sheet = workbook.Sheets[sheet_name_list[0]];
      const json_data = utils.sheet_to_json(sheet);

      // let newData = json_data.reduce((acc, item) => {
      //   let keys = Object.keys(item);
      //   for (const key of keys) {
      //     if (!acc[key]) acc[key] = {};
      //     acc[key][item.TAHUN] = item[key];
      //   }
      //   return acc;
      // }, {});

      const newData = json_data.reduce((acc, curr) => {
        Object.keys(curr).forEach((key) => {
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(curr[key]);
        });
        return acc;
      }, {});

      // setJsonData(newData)
      jsonData(newData);
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  return (
    <form>
      <input type="file" onChange={handleFileChange} />
    </form>
  );
}

// export default XlsxToJson;
