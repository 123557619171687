import React, { useState } from "react";

export default function InsertCategory({ submit }) {
  const [categoryName, setCategoryName] = useState("");

  return (
    <div
      className="modal fade"
      id="insertCategory"
      tabIndex={"-1"}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Tambah Kategori
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Kategori"
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button form="insertCategoryForm" type="clear" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button
              onClick={() => submit(categoryName)}
              form="insertCategoryForm"
              type="submit"
              className="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
