import React from "react";
import { Pie } from "react-chartjs-2";

export default function PieChart({ title, lbl, chartData, update, category, agency }) {
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="container w-100 bg-white">
      {title ? <h6 className="pt-2">{title}</h6> : null}
      <div className="w-100 d-flex justify-content-center" style={{ height: "50vh", width: "100%" }}>
        {chartData ? (
          <Pie
            data={{
              labels: lbl,
              datasets: [
                {
                  data: chartData,
                  backgroundColor: chartData.map(() => getRandomColor()),
                  // hoverBackgroundColor: chartData.map(() => getRandomColor()),
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                },
              },
            }}
          />
        ) : null}
      </div>
      {update ? (
        <span className="small" style={{ fontSize: 12 }}>
          Updated : {update} | Kategori : {category} | Dinas : {agency}
        </span>
      ) : null}
    </div>
  );
}
