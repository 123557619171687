const data = [
  {
    id_lokasi: "1",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MERDEKA.m3u8",
    nama_lokasi: "SP. LAP MERDEKA ",
    lat_lokasi: "3.590017",
    lon_lokasi: "98.67763",
  },
  {
    id_lokasi: "2",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/LONSUM.m3u8",
    nama_lokasi: "SP lONSUM",
    lat_lokasi: "3.588852",
    lon_lokasi: "98.678276",
  },
  {
    id_lokasi: "3",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KESAWAN.m3u8",
    nama_lokasi: "SP KESAWAN",
    lat_lokasi: "3.584566",
    lon_lokasi: "98.680644",
  },
  {
    id_lokasi: "4",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/WASPADA.m3u8",
    nama_lokasi: "SP WASPADA ",
    lat_lokasi: "3.581282",
    lon_lokasi: "98.682542",
  },
  {
    id_lokasi: "5",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/TIRTANADI.m3u8",
    nama_lokasi: "SP TIRTANADI ",
    lat_lokasi: "3.582234",
    lon_lokasi: "98.684669",
  },
  {
    id_lokasi: "6",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ISTANAMAIMUN.m3u8",
    nama_lokasi: "SP ISTANA MAIMUN ",
    lat_lokasi: "3.575387",
    lon_lokasi: "98.684861",
  },
  {
    id_lokasi: "7",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MASJIDRAYA.m3u8",
    nama_lokasi: "SP MESJID RAYA",
    lat_lokasi: "3.575927",
    lon_lokasi: "98.68759",
  },
  {
    id_lokasi: "8",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MAYESTIC.m3u8",
    nama_lokasi: "SP MAJESTIK ",
    lat_lokasi: "3.593382",
    lon_lokasi: "98.668696",
  },
  {
    id_lokasi: "9",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/LAPANGANBENTENG1.m3u8",
    nama_lokasi: "SP WISMA BENTENG ",
    lat_lokasi: "3.589639",
    lon_lokasi: "98.673152",
  },
  {
    id_lokasi: "10",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/LAPANGANBENTENG1.m3u8",
    nama_lokasi: "SP LAP.BENTENG",
    lat_lokasi: "3.586873",
    lon_lokasi: "98.673646",
  },
  {
    id_lokasi: "11",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/HDTI.m3u8",
    nama_lokasi: "SP HDTI",
    lat_lokasi: "3.583652",
    lon_lokasi: "98.675175",
  },
  {
    id_lokasi: "12",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MULTATULI.m3u8",
    nama_lokasi: "SP PIZZA HUT",
    lat_lokasi: "3.578632",
    lon_lokasi: "98.678245",
  },
  {
    id_lokasi: "13",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/IMMANUEL.m3u8",
    nama_lokasi: "SP AIR MANCUR",
    lat_lokasi: "3.577874",
    lon_lokasi: "98.67712",
  },
  {
    id_lokasi: "14",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/BII.m3u8",
    nama_lokasi: "SP BII",
    lat_lokasi: "3.583472",
    lon_lokasi: "98.672695",
  },
  {
    id_lokasi: "15",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KARTINI.m3u8 ",
    nama_lokasi: "SP KARTINI",
    lat_lokasi: "3.579803",
    lon_lokasi: "98.672473",
  },
  {
    id_lokasi: "16",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/WALIKOTA.m3u8",
    nama_lokasi: "SP WALIKOTA ",
    lat_lokasi: "3.575237",
    lon_lokasi: "98.672013",
  },
  {
    id_lokasi: "17",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GUBERNUR.m3u8",
    nama_lokasi: "SP GUBERNUR",
    lat_lokasi: "3.575057",
    lon_lokasi: "98.670079",
  },
  {
    id_lokasi: "18",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ISTANAPLAZA.m3u8",
    nama_lokasi: "SP ISTANA PLAZA",
    lat_lokasi: "3.572413",
    lon_lokasi: "98.685623",
  },
  {
    id_lokasi: "19",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/POLONIA.m3u8",
    nama_lokasi: "SP POLONIA ",
    lat_lokasi: "3.572138",
    lon_lokasi: "98.677818",
  },
  {
    id_lokasi: "20",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SMRAJAHALAT.m3u8",
    nama_lokasi: "SP HALAT",
    lat_lokasi: "3.572422",
    lon_lokasi: "98.689281",
  },
  {
    id_lokasi: "21",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SMRAJAPELANGI.m3u8  ",
    nama_lokasi: "SP SM.RAJA-PELANGI",
    lat_lokasi: "3.562739",
    lon_lokasi: "98.69295",
  },
  {
    id_lokasi: "22",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SMRAJAPMH.m3u8",
    nama_lokasi: "SP SM.RAJA-PMH",
    lat_lokasi: "3.539185",
    lon_lokasi: "98.700927",
  },
  {
    id_lokasi: "23",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/AMPLAS.m3u8",
    nama_lokasi: "SP AMPLAS",
    lat_lokasi: "3.536032",
    lon_lokasi: "98.718024",
  },
  {
    id_lokasi: "24",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/UNILAND.m3u8",
    nama_lokasi: "SP UNILAND",
    lat_lokasi: "3.586019",
    lon_lokasi: "98.683005",
  },
  {
    id_lokasi: "25",
    vidUrl: " https:////atcsdishub.pemkomedan.go.id/camera/MEDANMALL.m3u8  ",
    nama_lokasi: "SP MEDAN MALL ",
    lat_lokasi: "3.58709",
    lon_lokasi: "98.685733",
  },
  {
    id_lokasi: "26",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MTHARYONOTHAMRIN.m3u8",
    nama_lokasi: "SP MT HARYONO - THAMRIN",
    lat_lokasi: "3.588905",
    lon_lokasi: "98.690301",
  },
  {
    id_lokasi: "27",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/TVRI.m3u8  ",
    nama_lokasi: "SP TVRI",
    lat_lokasi: "3.595682",
    lon_lokasi: "98.675844",
  },
  {
    id_lokasi: "29",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUDIRMANUJUNG.m3u8",
    nama_lokasi: "SP SUDIRMAN UJUNG",
    lat_lokasi: "3.577219",
    lon_lokasi: "98.666707",
  },
  {
    id_lokasi: "30",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MONGONSIDIPATTIMURA.m3u8",
    nama_lokasi: "SP MONGINSIDI-PATTIMURA",
    lat_lokasi: "3.572711",
    lon_lokasi: "98.661863",
  },
  {
    id_lokasi: "31",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/JAMINGINTINGISMUD.m3u8  ",
    nama_lokasi: "SP JM.GINTING-ISMUD",
    lat_lokasi: "3.571738",
    lon_lokasi: "98.660966",
  },
  {
    id_lokasi: "32",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/JAMINGINTINGDRMANSYUR.m3u8",
    nama_lokasi: "SP JM.GNTNG-DR.MANSYUR",
    lat_lokasi: "3.567371",
    lon_lokasi: "98.660766",
  },
  {
    id_lokasi: "33",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SMRAJAHMJHONI.m3u8",
    nama_lokasi: "SP HM.JONI ",
    lat_lokasi: "3.568577",
    lon_lokasi: "98.691037",
  },
  {
    id_lokasi: "34",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SMRAJAALFALAH.m3u8",
    nama_lokasi: "SP AL FALAH",
    lat_lokasi: "3.546411",
    lon_lokasi: "98.698567",
  },
  {
    id_lokasi: "35",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/NASUTIONBAJAK.m3u8",
    nama_lokasi: "SP BAJAK ",
    lat_lokasi: "3.538458",
    lon_lokasi: "98.698181",
  },
  {
    id_lokasi: "36",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MEDANPLAZA.m3u8",
    nama_lokasi: "SP MEDAN PLAZA",
    lat_lokasi: "3.590632",
    lon_lokasi: "98.661412",
  },
  {
    id_lokasi: "37",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SEIWAMPU.m3u8Request Me",
    nama_lokasi: "SP SEI WAMPU ",
    lat_lokasi: "3.589985",
    lon_lokasi: "98.658193",
  },
  {
    id_lokasi: "38",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/AYAHANDA.m3u8",
    nama_lokasi: "SP AYAHANDA ",
    lat_lokasi: "3.590227",
    lon_lokasi: "98.653231",
  },
  {
    id_lokasi: "39",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SEISIKAMBING.m3u8",
    nama_lokasi: "SP SEI SIKAMBING",
    lat_lokasi: "3.590731",
    lon_lokasi: "98.643873",
  },
  {
    id_lokasi: "40",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ISMUDGAJAHMADA.m3u8  ",
    nama_lokasi: "SP ISMUD-GAJAH MADA ",
    lat_lokasi: "3.584606",
    lon_lokasi: "98.661183",
  },
  {
    id_lokasi: "41",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ISMUDHASANUDDIN.m3u8",
    nama_lokasi: "sp ISMUD-HASANUDDIN ",
    lat_lokasi: "3.583064",
    lon_lokasi: "98.661179",
  },
  {
    id_lokasi: "42",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ISMUDABDLUBIS.m3u8",
    nama_lokasi: "sp ISMUD-ABD.LUBIS",
    lat_lokasi: "3.576719",
    lon_lokasi: "98.660867",
  },
  {
    id_lokasi: "43",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KEJAKSAANSPARMAN.m3u8",
    nama_lokasi: "sp KEJAKSAAN-S.PARMAN ",
    lat_lokasi: "3.587442",
    lon_lokasi: "98.667349",
  },
  {
    id_lokasi: "44",
    vidUrl: " https:////atcsdishub.pemkomedan.go.id/camera/GAHARUPERINTIS.m3u8  ",
    nama_lokasi: "sp GAHARU-PERINTIS ",
    lat_lokasi: "3.596985",
    lon_lokasi: "98.678228",
  },
  {
    id_lokasi: "45",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/NOUMENSEN.m3u8",
    nama_lokasi: "sp NOUMENSEN",
    lat_lokasi: "3.598246",
    lon_lokasi: "98.68148",
  },
  {
    id_lokasi: "46",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUTOMOYAMIN.m3u8",
    nama_lokasi: "sp SUTOMO-YAMIN",
    lat_lokasi: "3.595062",
    lon_lokasi: "98.682539",
  },
  {
    id_lokasi: "47",
    vidUrl: " https:////atcsdishub.pemkomedan.go.id/camera/GLUGUR.m3u8  ",
    nama_lokasi: "sp GELUGUR ",
    lat_lokasi: "3.606797",
    lon_lokasi: "98.673962",
  },
  {
    id_lokasi: "48",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/BILALYOSSUDARSO.m3u8",
    nama_lokasi: "sp BILAL ",
    lat_lokasi: "3.622397",
    lon_lokasi: "98.669957",
  },
  {
    id_lokasi: "49",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/BRAYAN.m3u8",
    nama_lokasi: "sp BRAYAN ",
    lat_lokasi: "3.628387",
    lon_lokasi: "98.669315",
  },
  {
    id_lokasi: "50",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GAJAHMADAWAHIDHASYIM.m3u8",
    nama_lokasi: "sp GAJAH MADA-W.HASYIM",
    lat_lokasi: "3.58487",
    lon_lokasi: "98.65713",
  },
  {
    id_lokasi: "51",
    vidUrl: " https:////atcsdishub.pemkomedan.go.id/camera/GRIYA.m3u8  ",
    nama_lokasi: "sp GRIYA ",
    lat_lokasi: "3.604875",
    lon_lokasi: "98.645706",
  },
  {
    id_lokasi: "52",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUNGGALKASUARI.m3u8",
    nama_lokasi: "sp SUNGGAL-KASUARI",
    lat_lokasi: "3.584456",
    lon_lokasi: "98.641315",
  },
  {
    id_lokasi: "53",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/THAMRINYAMIN.m3u8",
    nama_lokasi: "sp THAMRIN-YAMIN ",
    lat_lokasi: "3.596883",
    lon_lokasi: "98.687526",
  },
  {
    id_lokasi: "54",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KEJAKSAANTUMAR.m3u8",
    nama_lokasi: "sp KEJAKSAAN-T.UMAR ",
    lat_lokasi: "3.587017",
    lon_lokasi: "98.671538",
  },
  {
    id_lokasi: "55",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/JUANDAWALIKOTA.m3u8",
    nama_lokasi: "sp JUANDA-WALIKOTA ",
    lat_lokasi: "3.573545",
    lon_lokasi: "98.671207",
  },
  {
    id_lokasi: "56",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/RAHMADSYAH.m3u8",
    nama_lokasi: "sp RAHMADSYAH",
    lat_lokasi: "3.57965",
    lon_lokasi: "98.685787",
  },
  {
    id_lokasi: "57",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/STMAHNASUTION.m3u8",
    nama_lokasi: "sp STM TRITURA ",
    lat_lokasi: "3.537974",
    lon_lokasi: "98.691329",
  },
  {
    id_lokasi: "58",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/AMAL.m3u8",
    nama_lokasi: "sp AMAL-GAGAK HITAM",
    lat_lokasi: "3.584795",
    lon_lokasi: "98.626663",
  },
  {
    id_lokasi: "59",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUGGAL.m3u8",
    nama_lokasi: "sp SUNGGAL-GAGAK HITAM",
    lat_lokasi: "3.578528",
    lon_lokasi: "98.626623",
  },
  {
    id_lokasi: "60",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ASOKA.m3u8",
    nama_lokasi: "sp ASOKA-GAGAK HITAM ",
    lat_lokasi: "3.562668",
    lon_lokasi: "98.62617",
  },
  {
    id_lokasi: "61",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SETIABUDIINDUSTRI.m3u8",
    nama_lokasi: "sp SETIA BUDI-INDUSTRI",
    lat_lokasi: "3.545806",
    lon_lokasi: "98.626069",
  },
  {
    id_lokasi: "62",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SIMPANGPOS.m3u8",
    nama_lokasi: "sp SIMPANG POS ",
    lat_lokasi: "3.540614",
    lon_lokasi: "98.654322",
  },
  {
    id_lokasi: "63",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KARYAWISATA.m3u8",
    nama_lokasi: "sp KARYA WISATA",
    lat_lokasi: "3.541596",
    lon_lokasi: "98.662692",
  },
  {
    id_lokasi: "64",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KATAMSOPELANGI.m3u8",
    nama_lokasi: "sp KATAMSO - PELANGI",
    lat_lokasi: "3.562775",
    lon_lokasi: "98.689911",
  },
  {
    id_lokasi: "65",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KATAMSOSAKTI%20LUBIS.m3u8",
    nama_lokasi: "sp KATAMSO - SAKTI LUBIS",
    lat_lokasi: "3.553113",
    lon_lokasi: "98.688722",
  },
  {
    id_lokasi: "66",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUTOMOSUTRISNO.m3u8",
    nama_lokasi: "sp SUTOMO - SUTRISNO",
    lat_lokasi: "3.580155",
    lon_lokasi: "98.688875",
  },
  {
    id_lokasi: "67",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/THAMRINSUTRISNO.m3u8",
    nama_lokasi: "sp THAMRIN - SUTRISNO ",
    lat_lokasi: "3.581166",
    lon_lokasi: "98.693558",
  },
  {
    id_lokasi: "68",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/THAMRINASIA.m3u8",
    nama_lokasi: "sp THAMRIN - ASIA ",
    lat_lokasi: "3.585013",
    lon_lokasi: "98.692019",
  },
  {
    id_lokasi: "69",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUTOMOMERBABU.m3u8",
    nama_lokasi: "sp SUTOMO - MARBABU",
    lat_lokasi: "3.58558",
    lon_lokasi: "98.686394",
  },
  {
    id_lokasi: "70",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GAHARUBAMBU2.m3u8",
    nama_lokasi: "sp GAHARU - BAMBU II",
    lat_lokasi: "3.607136",
    lon_lokasi: "98.676952",
  },
  {
    id_lokasi: "71",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUTOMOBAMBU2.m3u8",
    nama_lokasi: "sp SUTOMO - BAMBU II",
    lat_lokasi: "3.607197",
    lon_lokasi: "98.679983",
  },
  {
    id_lokasi: "72",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KRAKATAUPASAR3.m3u8",
    nama_lokasi: "sp KRAKATAU - PASAR III ",
    lat_lokasi: "3.616788",
    lon_lokasi: "98.680869",
  },
  {
    id_lokasi: "73",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KRAKATAUBILAL.m3u8",
    nama_lokasi: "sp KRAKATAU - BILAL ",
    lat_lokasi: "3.622813",
    lon_lokasi: "98.681036",
  },
  {
    id_lokasi: "74",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KRAKATUSIDORUKUN.m3u8",
    nama_lokasi: "sp KRAKATU - SIDORUKUN ",
    lat_lokasi: "3.625986",
    lon_lokasi: "98.681066",
  },
  {
    id_lokasi: "75",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ABDLUBISWAHIDHASYIM.m3u8",
    nama_lokasi: "sp ABDULAH LUBIS - WAHID HASYIM ",
    lat_lokasi: "3.576846",
    lon_lokasi: "98.657322",
  },
  {
    id_lokasi: "76",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KRAKATAUCEMARA.m3u8",
    nama_lokasi: "sp KRAKATAU - CEMARA ",
    lat_lokasi: "3.629786",
    lon_lokasi: "98.681084",
  },
  {
    id_lokasi: "77",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SKIPPABRIKTENUN.m3u8",
    nama_lokasi: "sp SEKIP - P TENUN",
    lat_lokasi: "3.598343",
    lon_lokasi: "98.663713",
  },
  {
    id_lokasi: "78",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ABDLUBISSEISERAYU.m3u8",
    nama_lokasi: "sp ADB LBS - SEI BLUTU ",
    lat_lokasi: "3.577011",
    lon_lokasi: "98.65234",
  },
  {
    id_lokasi: "79",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GAJAHMADADARUSSALAM.m3u8",
    nama_lokasi: "sp Gajah Mada - Darussalam ",
    lat_lokasi: "3.585001",
    lon_lokasi: "98.65306",
  },
  {
    id_lokasi: "80",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MANGONSIDIDRCIPTO.m3u8",
    nama_lokasi: "sp Mongonsidi - DR Cipto ",
    lat_lokasi: "3.571215",
    lon_lokasi: "98.666358",
  },
  {
    id_lokasi: "81",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/HMYAMINPERINTIS.m3u8",
    nama_lokasi: "sp HM Yamin - Printis ",
    lat_lokasi: "3.598586",
    lon_lokasi: "98.691666",
  },
  {
    id_lokasi: "82",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GATSUASRAMA.m3u8",
    nama_lokasi: "sp Gatot Subroto - Asrama ",
    lat_lokasi: "3.591602",
    lon_lokasi: "98.627216",
  },
  {
    id_lokasi: "83",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ARHAKIMWILIAISKANDAR.m3u8",
    nama_lokasi: "sp HM Yamin - wiliam iskandar",
    lat_lokasi: "3.597988",
    lon_lokasi: "98.706443",
  },
  {
    id_lokasi: "84",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PINANGBARIS.m3u8",
    nama_lokasi: "sp KAMPUNG LALANG",
    lat_lokasi: "3.597952",
    lon_lokasi: "98.608962",
  },
  {
    id_lokasi: "85",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GAPERTAASRAMA.m3u8",
    nama_lokasi: "sp GAPERTA - ASRAMA",
    lat_lokasi: "3.605167",
    lon_lokasi: "98.627991",
  },
  {
    id_lokasi: "86",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MANDALA.m3u8",
    nama_lokasi: "sp LETDA SUJONO - MANDALA",
    lat_lokasi: "3.597734",
    lon_lokasi: "98.711723",
  },
  {
    id_lokasi: "87",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/RSADAMMALIK.m3u8",
    nama_lokasi: "sp ADAM MALIK",
    lat_lokasi: "3.514036",
    lon_lokasi: "98.615334",
  },
  {
    id_lokasi: "88",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SELAYANG.m3u8",
    nama_lokasi: "sp SELAYANG",
    lat_lokasi: "3.520051",
    lon_lokasi: "98.619046",
  },
  {
    id_lokasi: "89",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PEMDA.m3u8",
    nama_lokasi: "sp PEMDA ",
    lat_lokasi: "3.541019",
    lon_lokasi: "98.622381",
  },
  {
    id_lokasi: "90",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SIMALINGKAR.m3u8",
    nama_lokasi: "sp SIMALINGKAR ",
    lat_lokasi: "3.526387",
    lon_lokasi: "98.633127",
  },
  {
    id_lokasi: "91",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SEJATI.m3u8",
    nama_lokasi: "sp AKSARA - SEJATI",
    lat_lokasi: "3.59477",
    lon_lokasi: "98.706302",
  },
  {
    id_lokasi: "92",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/WAHIDIN.m3u8",
    nama_lokasi: "sp AR HAKIM - WAHIDIN ",
    lat_lokasi: "3.589175",
    lon_lokasi: "98.706065",
  },
  {
    id_lokasi: "93",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUKAIRAMAI.m3u8",
    nama_lokasi: "sp AR HAKIM - DENAI ",
    lat_lokasi: "3.582116",
    lon_lokasi: "98.703968",
  },
  {
    id_lokasi: "94",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/LAPANGANBENTENG2.m3u8",
    nama_lokasi: "sp Lapangan Benteng II",
    lat_lokasi: "3.586967",
    lon_lokasi: "98.67296",
  },
  {
    id_lokasi: "95",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SETIABUDIDRMANSYUR.m3u8",
    nama_lokasi: "sp SETIA BUDI DR MANSYUR",
    lat_lokasi: "3.56764",
    lon_lokasi: "98.6421",
  },
  {
    id_lokasi: "96",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/DEPANRSBUNDATHAMRIN.m3u8",
    nama_lokasi: "RUAS Jl. Sei Batanghari depan RS. Bunda Thamrin",
    lat_lokasi: "3.584898",
    lon_lokasi: "98.651012",
  },
  {
    id_lokasi: "97",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SEIULARBARU.m3u8",
    nama_lokasi: "RUAS Jl. Sei Batanghari Sp. Jl. Sei Ular Baru",
    lat_lokasi: "3.584918",
    lon_lokasi: "98.64766",
  },
  {
    id_lokasi: "98",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/TITIBOBROK.m3u8",
    nama_lokasi: "RUAS Jl. Setiabudi - Titi Bobrok",
    lat_lokasi: "3.58338",
    lon_lokasi: "98.643043",
  },
  {
    id_lokasi: "99",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PINTU4USU.m3u8",
    nama_lokasi: "RUAS Jl. Dr. Mansyur depan Pintu 4 USU",
    lat_lokasi: "3.567222",
    lon_lokasi: "98.655914",
  },
  {
    id_lokasi: "100",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PINTU2USU.m3u8",
    nama_lokasi: "RUAS Jl. Dr. Mansyur depan Pintu 2 USU",
    lat_lokasi: "3.567272",
    lon_lokasi: "98.65951",
  },
  {
    id_lokasi: "101",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SMRAJA.m3u8",
    nama_lokasi: "RUAS Jl. SM. Raja Sp. Jl. Saudara",
    lat_lokasi: "3.5583",
    lon_lokasi: "98.694468",
  },
  {
    id_lokasi: "102",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PULAUPINANG.m3u8",
    nama_lokasi: "RUAS Jl. Pulau Pinang Sp. Jl. Stasiun Kereta Api",
    lat_lokasi: "3.589763",
    lon_lokasi: "98.680139",
  },
  {
    id_lokasi: "103",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/JAWAVETERAN.m3u8",
    nama_lokasi: "RUAS Jl. Jawa Sp. Jl. Veteran",
    lat_lokasi: "3.590569",
    lon_lokasi: "98.681309",
  },
  {
    id_lokasi: "104",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/VETERANTIMOR.m3u8",
    nama_lokasi: "RUAS Jl. veteran - Jl. Timor",
    lat_lokasi: "3.590831",
    lon_lokasi: "98.68277",
  },
  {
    id_lokasi: "105",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/HMTHAMRIN.m3u8",
    nama_lokasi: "RUAS Jl. HM. Thamrin depan YP. Sutomo",
    lat_lokasi: "3.593075",
    lon_lokasi: "98.689005",
  },
  {
    id_lokasi: "106",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KEJAKSAAN.m3u8",
    nama_lokasi: "RUAS Jl. Kejaksaan depan KPU Kota Medan",
    lat_lokasi: "3.586844",
    lon_lokasi: "98.66891",
  },
  {
    id_lokasi: "107",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PUTRIHIJAU.m3u8",
    nama_lokasi: "RUAS PUTRI HIJAU H.M YAMIN ( DPN BRI )",
    lat_lokasi: "3.593045",
    lon_lokasi: "98.67671932",
  },
  {
    id_lokasi: "108",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KLYOSSUDARSO.m3u8",
    nama_lokasi: "RUAS Jl. KL. Yos Sudarso depan YP. Dharma Wangsa",
    lat_lokasi: "3.614151",
    lon_lokasi: "98.672605",
  },
  {
    id_lokasi: "109",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUTOMOTUGUKB.m3u8",
    nama_lokasi: "RUAS Jl. Sutomo depan Tugu KB",
    lat_lokasi: "3.609963",
    lon_lokasi: "98.680319",
  },
  {
    id_lokasi: "110",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PERINTISTIMOR.m3u8",
    nama_lokasi: "RUAS Jl. Perintis - Jl. Timor",
    lat_lokasi: "3.598441",
    lon_lokasi: "98.679525",
  },
  {
    id_lokasi: "111",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/JAWA.m3u8",
    nama_lokasi: "RUAS JL. JAWA (DEPAN CENTRE POINT)",
    lat_lokasi: "3.591695",
    lon_lokasi: "98.680486",
  },
  {
    id_lokasi: "112",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUTOMOTUGUAPOLO.m3u8",
    nama_lokasi: "RUAS JL. SUTOMO (TUGU APOLO)",
    lat_lokasi: "3.591238",
    lon_lokasi: "98.684363",
  },
  {
    id_lokasi: "113",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GATOTSUBRTONIBUNG.m3u8",
    nama_lokasi: "RUAS JL. GATOT SUBROTO SIMP JLN NIBUNG",
    lat_lokasi: "3.591893",
    lon_lokasi: "98.664773",
  },
  {
    id_lokasi: "114",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/RADENSALEH.m3u8",
    nama_lokasi: "RUAS JL. RADEN SALEH ( DPN KANTOR WALIKOTA )",
    lat_lokasi: "3.589758",
    lon_lokasi: "98.675192",
  },
  {
    id_lokasi: "115",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ZAINULARIFIN.m3u8",
    nama_lokasi: "RUAS JL. ZAINUL ARIFIN ( SUN PLAZA )",
    lat_lokasi: "3.583655",
    lon_lokasi: "98.671259",
  },
  {
    id_lokasi: "116",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/IMAMBONJOL.m3u8",
    nama_lokasi: "RUAS JL. IMAM BONJOL ",
    lat_lokasi: "3.588683",
    lon_lokasi: "98.674004",
  },
  {
    id_lokasi: "117",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/DIPONEGORO.m3u8",
    nama_lokasi: "RUAS JL. DIPONEGORO ( DEPAN MESJID AGUNG )",
    lat_lokasi: "3.581022",
    lon_lokasi: "98.672718",
  },
  {
    id_lokasi: "118",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/STASIUN.m3u8",
    nama_lokasi: "RUAS JL. STASIUN ",
    lat_lokasi: "3.587808",
    lon_lokasi: "98.680916",
  },
  {
    id_lokasi: "119",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MTHARYONO.m3u8",
    nama_lokasi: "RUAS JL. MT HARYONO ",
    lat_lokasi: "3.588448",
    lon_lokasi: "98.688749",
  },
  {
    id_lokasi: "120",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SPLIMUN.m3u8",
    nama_lokasi: "RUAS SP. LIMUN ",
    lat_lokasi: "3.55312",
    lon_lokasi: "98.696412",
  },
  {
    id_lokasi: "121",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/AMPLAS.m3u8",
    nama_lokasi: "RUAS JL. AMPLAS (Depan TERMINAL AMPLAS)",
    lat_lokasi: "3.540013",
    lon_lokasi: "98.717329",
  },
  {
    id_lokasi: "122",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SPARMANHASANUDDIN.m3u8",
    nama_lokasi: "RUAS JL. S PARMAN HASANUDDIN",
    lat_lokasi: "3.582809",
    lon_lokasi: "98.667302",
  },
  {
    id_lokasi: "123",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SPARMANZAINUDDIN.m3u8",
    nama_lokasi: "RUAS Depan Cambridge",
    lat_lokasi: "3.585098",
    lon_lokasi: "98.667166",
  },
  {
    id_lokasi: "124",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SPARMANGLUGUR.m3u8",
    nama_lokasi: "RUAS JL. S.Parman - Glugur",
    lat_lokasi: "3.590067",
    lon_lokasi: "98.66916",
  },
  {
    id_lokasi: "125",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KPTMAULANALUBISSPARMAN.m3u8    ",
    nama_lokasi: "RUAS JL. Tugu Guru Patimpus",
    lat_lokasi: "3.591142",
    lon_lokasi: "98.671288",
  },
  {
    id_lokasi: "126",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MERAKJINGGAHMYAMIN.m3u8",
    nama_lokasi: "RUAS Simp.Merak Jingga - HN.Yamin",
    lat_lokasi: "3.593645",
    lon_lokasi: "98.678145",
  },
  {
    id_lokasi: "127",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MERAKJINGGAPRINTISKEMERDEKAAN.m3u8",
    nama_lokasi: "RUAS Simp.Merak Jingga - Printis",
    lat_lokasi: "3.596627",
    lon_lokasi: "98.676943",
  },
  {
    id_lokasi: "128",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/HMSAIDPRINTISKEMERDEKAAN.m3u8",
    nama_lokasi: "RUAS JL. HM.Said - Simp Printis",
    lat_lokasi: "3.598715",
    lon_lokasi: "98.683893",
  },
  {
    id_lokasi: "129",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/THAMRINPRINTISKEMERDEKAAN.m3u8",
    nama_lokasi: "RUAS JL. Thamrin - Printis",
    lat_lokasi: "3.599225",
    lon_lokasi: "98.686533",
  },
  {
    id_lokasi: "130",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/CILINCING.m3u8",
    nama_lokasi: "RUAS Simp.Cilincing",
    lat_lokasi: "3.619678",
    lon_lokasi: "98.671263",
  },
  {
    id_lokasi: "131",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KARYATAMIRHAMZAH.m3u8",
    nama_lokasi: "RUAS Simp.Karya - Amir Hamzah",
    lat_lokasi: "3.606942",
    lon_lokasi: "98.665232",
  },
  {
    id_lokasi: "132",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ADAMMALIKTAMIRHAMZAH.m3u8",
    nama_lokasi: "RUAS Tugu Guru Adipura",
    lat_lokasi: "3.606862",
    lon_lokasi: "98.669681",
  },
  {
    id_lokasi: "133",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PAJAKHINDU.m3u8",
    nama_lokasi: "RUAS Pajak Hindu",
    lat_lokasi: "3.587423",
    lon_lokasi: "98.677154",
  },
  {
    id_lokasi: "134",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/BUNDARANPOLONIA.m3u8",
    nama_lokasi: "RUAS Bundaran Polonia",
    lat_lokasi: "3.569469",
    lon_lokasi: "98.677629",
  },
  {
    id_lokasi: "135",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/BUNDARANHOTELPARDEDE.m3u8",
    nama_lokasi: "RUAS Bundaran Hotel Pardede",
    lat_lokasi: "3.573015",
    lon_lokasi: "98.672751",
  },
  {
    id_lokasi: "136",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/DEPANTUGUSMRAJA.m3u8",
    nama_lokasi: "RUAS Tugu SM.Raja (Depan Ramayana)",
    lat_lokasi: "3.564955",
    lon_lokasi: "98.692316",
  },
  {
    id_lokasi: "137",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/JUANDASAMANHUDI.m3u8",
    nama_lokasi: "RUAS JL. Juanda - Saman Hudi",
    lat_lokasi: "3.572415",
    lon_lokasi: "98.681583",
  },
  {
    id_lokasi: "138",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KPTMMUSLIM.m3u8",
    nama_lokasi: "RUAS JL. Kptn.Muslim (Depan MILENIUM)",
    lat_lokasi: "3.599318",
    lon_lokasi: "98.645256",
  },
  {
    id_lokasi: "139",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GAJAHMADA.m3u8",
    nama_lokasi: "RUAS Jl. Gajah Mada - Taman Gajah Mada",
    lat_lokasi: "3.584586",
    lon_lokasi: "98.658603",
  },
  {
    id_lokasi: "140",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SMRAJAHALAT.m3u8",
    nama_lokasi: "RUAS Jl. SM Raja - Halat",
    lat_lokasi: "3.572672",
    lon_lokasi: "98.688989",
  },
  {
    id_lokasi: "141",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/STADIUN.m3u8",
    nama_lokasi: "RUAS Jl. Stadiun - Simp Polsek Medan Kota",
    lat_lokasi: "3.564971",
    lon_lokasi: "98.693402",
  },
  {
    id_lokasi: "142",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUDIRMAN.m3u8",
    nama_lokasi: "RUAS Jl. Sudirman (Jembatan)",
    lat_lokasi: "3.576946",
    lon_lokasi: "98.668275",
  },
  {
    id_lokasi: "143",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/IMAMBONJOLCUTMUTIA.m3u8",
    nama_lokasi: "RUAS Jl. Imam Bonjol - Cut Mutia",
    lat_lokasi: "3.580976",
    lon_lokasi: "98.676651",
  },
  {
    id_lokasi: "144",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GATOTSUBROTO.m3u8",
    nama_lokasi: "RUAS Jl. Gatot Subroto (Jembatan UNPAB)",
    lat_lokasi: "3.59029",
    lon_lokasi: "98.64677",
  },
  {
    id_lokasi: "145",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GATOTSUBROTOPRSU.m3u8",
    nama_lokasi: "RUAS Jl. Gatot Subroto PRSU",
    lat_lokasi: "3.591121",
    lon_lokasi: "98.633254",
  },
  {
    id_lokasi: "146",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/AHMADYHANI.m3u8",
    nama_lokasi: "RUAS Jl. Ahmad Yani (Depan Tip-Top)",
    lat_lokasi: "3.58585",
    lon_lokasi: "98.679986",
  },
  {
    id_lokasi: "147",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/MONGONSIDIPPERHUBUNGAN.m3u8",
    nama_lokasi: "RUAS Jl. Mongonsidi - Perhubungan (Depan KFC)",
    lat_lokasi: "3.57132",
    lon_lokasi: "98.671817",
  },
  {
    id_lokasi: "148",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUTOMOANIIDRUS.m3u8",
    nama_lokasi: "RUAS Jl. Sutomo - HJ.Ani Idrus (Perlintasan KA)",
    lat_lokasi: "3.583366",
    lon_lokasi: "98.687272",
  },
  {
    id_lokasi: "149",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PALANGMERAHMESJID.m3u8",
    nama_lokasi: "RUAS Jl. Palang Merah - Mesjid",
    lat_lokasi: "3.584137",
    lon_lokasi: "98.678663",
  },
  {
    id_lokasi: "150",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PALANGMERAHLISTRIK.m3u8",
    nama_lokasi: "RUAS Jl. Palang Merah - Listrik",
    lat_lokasi: "3.584288",
    lon_lokasi: "98.676265",
  },
  {
    id_lokasi: "151",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SKIPMERANTI.m3u8",
    nama_lokasi: "RUAS Jl. Skip - Meranti",
    lat_lokasi: "3.594885",
    lon_lokasi: "98.666223",
  },
  {
    id_lokasi: "152",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/IMAMBONJOLJUANDA.m3u8",
    nama_lokasi: "RUAS Jl. Imam Bonjol - Juanda",
    lat_lokasi: "3.572087",
    lon_lokasi: "98.678001",
  },
  {
    id_lokasi: "153",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/DRCIPTOMASDULHAD.m3u8",
    nama_lokasi: "RUAS JL. Dr.Cipto - Masdulhad",
    lat_lokasi: "3.572166",
    lon_lokasi: "98.667075",
  },
  {
    id_lokasi: "154",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/SUPRAPTOIMAMBONJOL.m3u8",
    nama_lokasi: "RUAS Depan Taman A YANI",
    lat_lokasi: "3.577797",
    lon_lokasi: "98.67717",
  },
  {
    id_lokasi: "155",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GURUPATIMPUSSEIDELI.m3u8",
    nama_lokasi: "RUAS Jl. Guru patimpus - Sei Deli",
    lat_lokasi: "3.59444",
    lon_lokasi: "98.672211",
  },
  {
    id_lokasi: "156",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/HMJHONIGEDUNGARCA.m3u8",
    nama_lokasi: "RUAS JL. HM.Joni - Gedung Arca",
    lat_lokasi: "3.568569",
    lon_lokasi: "98.695663",
  },
  {
    id_lokasi: "157",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ISKANDARMUDA.m3u8",
    nama_lokasi: "RUAS Jl. Iskandar Muda (Depan Ramayana)",
    lat_lokasi: "3.580654",
    lon_lokasi: "98.661148",
  },
  {
    id_lokasi: "158",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/PUTRIHIJAUMERAKJINGGA.m3u8",
    nama_lokasi: "RUAS Jl. Putri Hijau simpang Merak Jingga",
    lat_lokasi: "3.600683",
    lon_lokasi: "98.673161",
  },
  {
    id_lokasi: "159",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/YOSSUDARSOABDWAHAB.m3u8",
    nama_lokasi: "RUAS Jl. YOS SUDARSO - ABD WAHAB ROKAN",
    lat_lokasi: "3.609257",
    lon_lokasi: "98.674224",
  },
  {
    id_lokasi: "160",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/STASIUNHALTEI.m3u8",
    nama_lokasi: "RUAS JL. STASIUN - BUKIT BARISAN (HALTE 1)",
    lat_lokasi: "3.592821",
    lon_lokasi: "98.678812",
  },
  {
    id_lokasi: "161",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/STASIUNHALTEII.m3u8",
    nama_lokasi: "RUAS JL. STASIUN - BUKIT BARISAN (HALTE 2)",
    lat_lokasi: "3.592044",
    lon_lokasi: "98.679099",
  },
  {
    id_lokasi: "162",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/STASIUNPALANGMERAH.m3u8",
    nama_lokasi: "RUAS JL. STASIUN - PALANG MERAH",
    lat_lokasi: "3.58542",
    lon_lokasi: "98.681834",
  },
  {
    id_lokasi: "163",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/RAHMADSYAHSUTOMO.m3u8",
    nama_lokasi: "RUAS JL. RAMADSYAH - SUTOMO",
    lat_lokasi: "3.579833",
    lon_lokasi: "98.688956",
  },
  {
    id_lokasi: "164",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/JAMINGINTINGPASARINDUK.m3u8",
    nama_lokasi: "RUAS JL. JAMIN GINTING - PASAR INDUK",
    lat_lokasi: "3.52012",
    lon_lokasi: "98.618991",
  },
  {
    id_lokasi: "165",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GATOTSUBROTOPINANGBARIS.m3u8",
    nama_lokasi: "RUAS JL. GATOT SUBROTO(Tugu Batas Kota) - PINANG BARIS",
    lat_lokasi: "3.599035",
    lon_lokasi: "98.605707",
  },
  {
    id_lokasi: "166",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GATOTSUBROTOPATRIOT.m3u8",
    nama_lokasi: "RUAS JL. GATOT SUBROTO - PATRIOT",
    lat_lokasi: "3.592832",
    lon_lokasi: "98.621483",
  },
  {
    id_lokasi: "167",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KRAKATAUBUKITBARISAN.m3u8",
    nama_lokasi: "RUAS JL. KAKATAU - BUKIT BARISAN 1",
    lat_lokasi: "3.614009",
    lon_lokasi: "98.680629",
  },
  {
    id_lokasi: "168",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/HMYAMINTIBRAHIMUMAR.m3u8",
    nama_lokasi: "RUAS JL. HM.YAMIN - T.IBRAHIM UMAR",
    lat_lokasi: "3.598486",
    lon_lokasi: "98.695895",
  },
  {
    id_lokasi: "169",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/KEPRIBADIANMAYOR.m3u8",
    nama_lokasi: "RUAS JL. KEPRIBADIAN - SIMP MAYOR",
    lat_lokasi: "3.588827",
    lon_lokasi: "98.676495",
  },
  {
    id_lokasi: "170",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/BAMBU1.m3u8",
    nama_lokasi: "RUAS JL. BAMBU 1",
    lat_lokasi: "3.604516",
    lon_lokasi: "98.679793",
  },
  {
    id_lokasi: "171",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/ARHAKIMHALAT.m3u8",
    nama_lokasi: "SP JL.AR.HAKIM - HALAT",
    lat_lokasi: "3.57223",
    lon_lokasi: "98.703278",
  },
  {
    id_lokasi: "172",
    vidUrl: "https:////atcsdishub.pemkomedan.go.id/camera/GEDUNGARCA.m3u8",
    nama_lokasi: "SP JL.HM JHONI - GEDUNG ARCA",
    lat_lokasi: "3.56875",
    lon_lokasi: "98.69589",
  },
];

export default data;
